import React from "react";
import Layout from "../../src/components/layout";
import Header from "../../src/molecules/GokenIndia/Header";
import SEO from "../../src/components/seo";
import OurStory from "../../src/molecules/GokenIndia/OurStory";
import AboutImage from "../../src/molecules/GokenIndia/AboutImage";
import OurClients from "../../src/molecules/GokenIndia/OurClients";
import HowWeServeClients from "../../src/molecules/GokenIndia/HowWeServeClients";
import SectionDivider from "../../src/molecules/GokenIndia/SectionDivider";
import GokensContribution from "../../src/molecules/GokenIndia/GokensContribution";
import Insights from "../../src/molecules/Insights";
import { useStaticQuery, graphql } from "gatsby";
import bgImage from "../images/gokenIndia/goken-in-bg.png";
import mobileImage from "../images/gokenIndia/bg-gix.jpg";
// import StaffingNewTimelineComponent from "../../src/molecules/staffing/staffingNewTimelineComponent";
// import StaffingDescription from "../../src/molecules/staffing/staffingDescription";
// import valueImage from "../images/gokenIndia/GIX__value_addition.png";
const processData = [
  {
    id: 1,
    heading: "System Requirements",
    paragraph: `Understand vehicle targets: Eg.. Sporty Feel, Weight Reduction`,
    image: "",
    isList: true,
    listItem: [
      "Define Functionality",
      "Help meet regulatory requirements",
      "VA/VE",
      "Design Conversion (Metal to plastic, 3D printing/RPT to injection moulding or any other process)",
    ],
  },
  {
    id: 2,
    heading: "Component Level Requirements",
    paragraph:
      "Vehicle targets reflected in system / component targets: e.g.: Good aesthetics & Stiffness, light-weight",
    image: "",
    isList: true,
    listItem: [
      "Ensure Fit-Function-Finish",
      "Material Selection",
      "Moulding process selection",
      "BOM generation",
      "Part Packaging in vehicle",
    ],
  },
  {
    id: 3,
    heading: "Product Design",
    paragraph:
      "3D design of components to ensure performance by understanding constraints for packaging, manufacturing, assembly (with tolerance stack-up), while achieving cost & weight targets",
    image: "",
    isList: true,
    listItem: [
      "Product Design concept to release",
      "3D CAD parametric modelling",
      "Master section – tooling, assembly, packaging  & regulatory check",
      "Styling surface analysis",
      "RPS selection and GD&T application",
      "Locating & Mounting Scheme (Degree of freedom: 3-2-1 principle)",
      "3D Annotation",
    ],
  },
  {
    id: 4,
    heading: "2D Generation & Report",
    paragraph:
      "Generate 2D data with GD&T to ensure seamless manufacturability",
    image: "",
    isList: true,
    listItem: [
      "2D Drawing ",
      "Bill Of Material",
      "Product & tooling feasibility report",
    ],
  },
  {
    id: 5,
    heading: "Design Verification & Validation",
    paragraph: "",
    image: "",
    isList: true,
    listItem: [
      "DFA & DFM",
      "DFMEA",
      "DVP&R",
      "Tolerance stack-up Analysis",
      "CAE",
      "FTR report",
    ],
  },
];
const GokenIndia = () => {
  const data = useStaticQuery(graphql`
    query GetGIXData {
      gcms {
        servicePages(where: { pageType: GIX }) {
          headerVideo {
            url
          }
          insightPages {
            shortDescription
            title
            slug
            image {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
    }
  `);
  console.log(data.gcms.servicePages[0]);
  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={"https://www.goken-global.com/gokenindia/"}
        title="Goken India: Goken India - Expanding Horizons"
        pageName={"Goken India"}
        description={
          "Goken India provides innovative engineering solutions and services to drive industry excellence."
        }
        facebookTitle={`Goken India: Goken India - Expanding Horizons`}
        facebookDescription={`Discover Goken India's revolutionary approach to engineering and technology. Explore our innovative solutions that drive efficiency and excellence in the global market. Click to learn more!`}
      />
      <div className="gix__page_wrapper">
        <Header
          headingOne={"Goken"}
          headingTwo={"India"}
          first={{ smallTitle: "Established In", bigTitle: "2019" }}
          second={{ smallTitle: "Located In", bigTitle: "Pune India" }}
          bgImage={bgImage}
          mobileImage={mobileImage}
        />
        <OurStory
          preTitle={"Our"}
          heading={"Story"}
          paragraph={`The purpose of setting up Goken’s India office was to offer a strong pillar of support for Goken’s global operations. Goken India’s vision is to establish Goken as a global brand in the areas of software application development and engineering services for the automotive, aerospace and manufacturing sectors. 
          It also supports Goken global operations in multiple ways such as Talent Management, Marketing and 
          Business Development.`}
          btnText={"Work With Us"}
          link={"/contactus/"}
        />
        {/* <AboutImage media={data.gcms.servicePages[0].headerVideo} /> */}
        <OurClients smallHeading={"A few of "} bigHeading={"Our Clients"} />
        {/* <StaffingNewTimelineComponent
          bannerService={"Goken India"}
          
          items={processData}
          style={"staffing"}
        /> */}
        {/* <StaffingDescription
          heading={"Where Goken India adds Value"}
          image={valueImage}
          description={" "}
        /> */}
        <HowWeServeClients />
        <SectionDivider />
        <GokensContribution />
      </div>
      <div className="w-90-wrapper">
        <Insights
          insights={data.gcms.servicePages[0].insightPages}
          showTitle={true}
          thumbnails={true}
        />
      </div>
    </Layout>
  );
};

export default GokenIndia;
